@media (min-width: 575px) {}





// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {


}





// Large devices (desktops, 992px and up)
@media (min-width: 992px) {

	.navbar-toggler{}//navbar-toggler

}





// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {}